import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import HeadSection from '~/components/blocks/HeadSection'
import EbookForm from '~/components/forms/EbookForm'
import MainLayout from '~/components/layouts/MainLayout'
import LazyHydrate from '~/components/shared/LazyHydrate'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { fileToImageLikeData } from '~/utils'
import InfoSectionLeadMagnets from '~/views/EbookLandings/components/InfoSectionLeadMagnets'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'
import usePwaDevelopmentEbookStaticQuery from '~/views/EbookLandings/PwaDevelopmentEbook/usePwaDevelopmentEbookStaticQuery'

import * as containerStyles from './PwaDevelopmentEbook.module.scss'

const dataList = [
  'What is a progressive web application',
  'How a PWA can benefit your business',
  'Examples of successful PWAs by world-famous brands',
  'Characteristics of PWAs',
  'Progressive web apps vs native mobile apps',
  'How to create a PWA',
  'What is the cost to build a progressive web app',
  'How to hire a PWA development company',
]

const PwaDevelopmentEbook = () => {
  const query = usePwaDevelopmentEbookStaticQuery()

  const imageEbook = getImage(fileToImageLikeData(query.imageEbook))
  const imageEbookBusiness = getImage(
    fileToImageLikeData(query.imageEbookBusiness),
  )

  return (
    <MainLayout isTransparent>
      <PrimeSectionLeadMagnets
        title="PWAs: Cost-Effective Alternative to Mobile Apps [Free E-book]"
        description={
          <>
            Looking for ways to save costs on
            <Link
              to="/services/mobile-app-development/"
              className="ml5"
              key="linkPwaDevEbook"
            >
              mobile app development services
            </Link>
            ? A PWA is a perfect option if you need a mobile solution created
            quickly and on budget
          </>
        }
        maxWidthTitle="900px"
        maxWidthDescription="830px"
      />

      <InfoSectionLeadMagnets
        imageEbook={imageEbookBusiness}
        imageClass="pwaDevelopmentEbookOne"
        imageMeta="PWAs: Cost-Effective Alternative to Mobile Apps | Codica"
        description={
          <>
            With
            <Link
              to="/services/progressive-web-apps-development/"
              className="ml5"
              key="pwaKeyDescriptionLink"
            >
              progressive web application development services
            </Link>
            , you don’t need to spend time and money building two separate
            mobile apps. Fast-loading, engaging, working offline,
            easy-to-install, a PWA is a modern cross-platform solution that
            works smoothly on Android and iOS devices.
            <span className="mb-4 d-block" key="keyOne" />
            How can this technology benefit your business? By implementing a
            PWA, the world’s top brands have managed to improve user retention,
            grow sales and increase conversion rate up to 3x times. And all this
            - without having to spend a fortune.
            <span className="mb-4 d-block" key="keyTwo" />
            Our expert team has prepared a free comprehensive E-book on
            progressive web apps. It will answer all your questions about the
            technology, and provide details on what you need to develop a PWA
            for your business.
          </>
        }
        isButton
        buttonName="Download E-book"
        buttonId="downloadPwaDevelopmentEbook"
      />

      <section className={containerStyles.pwaDevelopmentEbook__isbgGrey}>
        <HeadSection
          position="center"
          title="What’s inside?"
          description="All you need to know about creating a mobile solution with a PWA instead of a native mobile app, and how a progressive web app can boost your business:"
          widthDescription="830px"
        />
        <InfoSectionLeadMagnets
          imageEbook={imageEbook}
          imageClass="pwaDevelopmentEbook"
          imageMeta="Progressive Web Apps: a Cost-Effective Alternative to Native Mobile Apps | Codica"
          dataList={dataList}
        />
        <div
          id="downloadPdf"
          className={containerStyles.pwaDevelopmentEbook__downloadEbook}
        >
          <LazyHydrate whenVisible>
            <ReCaptchaProvider>
              <EbookForm
                fileName="E-book Progressive Web Apps All You Need to know"
                urlPath="thank-you-progressive-web-apps-pwa-development-ebook"
                formTitle="Download free E-book and know all about PWAs"
                dataId="downloadPWAEbook"
              />
            </ReCaptchaProvider>
          </LazyHydrate>
        </div>
      </section>
    </MainLayout>
  )
}

export default PwaDevelopmentEbook
