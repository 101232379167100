import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiPage } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import PwaDevelopmentEbook from '~/views/EbookLandings/PwaDevelopmentEbook'

const PWADevelopmentEbookPage = () => <PwaDevelopmentEbook />

export default PWADevelopmentEbookPage

export const query = graphql`
  query {
    strapiPage(
      page_name: { eq: "Progressive web apps pwa development ebook" }
    ) {
      ...MetaTagInformationPages
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiPage>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/progressive-web-apps-pwa-development-ebook/"
    />
  )
}
